import React from "react";

const Item2 = ({ Title, Btn, handleClick }) => {
    return (
        <div 
            className="item-1-wrap"
            onClick={handleClick} 
        >
            <span className="item-1-title">{Title}</span>
            <span className="item-1-btn-abl">{Btn}</span>
        </div>
    );
};

export default Item2;