import React, { useState, useEffect } from 'react';
import NavBar from "../NavBar";
import QuizBlock from "../components/items/QuizBlock";
import Item1 from "../components/items/Item1";
import Item2 from "../components/items/Item2";
import PostItem from "../components/items/PostItem";

import Item3 from "../components/items/Item3";

const TasksPage = ({ currentPage, setCurrentPage, userData }) => {
    
    const initialTaskStatus = [false, false, false, false, false]; // Изначально все задачи не выполнены
    const [taskStatus, setTaskStatus] = useState(initialTaskStatus);

    

    useEffect(() => {
        // Разбираем строки JSON из userData
        const tasks = JSON.parse(userData.tasks || '{}');
        const updatedStatus = [...initialTaskStatus];

        // Устанавливаем статус задач на основе данных из userData
        for (let i = 0; i < updatedStatus.length; i++) {
            if (tasks[i + 1] === 'finished') {
                updatedStatus[i] = true; // Устанавливаем статус как выполненный
            }
        }
        setTaskStatus(updatedStatus);
    }, [userData]);


    const handleClick = (url, userId, index) => {
        return async () => {
            // Открываем ссылку сразу для третьего и последующих элементов
            if (index >= 2) {
                window.open(url, '_blank');
            }
    
            try {
                // Отправляем запрос
                const response = await fetch(`https://webapp.ula.global/api/tasks/${userId}/ClaimTask/${index + 1}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ /* данные, если нужно */ }),
                });
    
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
    
                // Обновляем статус задачи
                setTaskStatus(prevStatus => {
                    const newStatus = [...prevStatus];
                    newStatus[index] = true; // Устанавливаем статус задачи как выполненный
                    return newStatus;
                });
            } catch (error) {
                console.error('Error while claiming task:', error);
            }
        };
    };
    


    return (
        <>
            <span className="title-type-1">Задания</span>
            <QuizBlock setCurrentPage={setCurrentPage}/>
            <Item3 
                Img={'/img/1 task.svg'} 
                Title={'Пройти опрос'} 
                Desc={'+50 баллов'} 
                Btn={'Выполнить'}
                status={taskStatus[0]}
                handleClick={() => {
                    if (!taskStatus[0]) {
                        handleClick(userData.user_id, 0); // Передаем userId и индекс
                        setCurrentPage('Quiz'); // Переход на страницу опроса
                    }
                }}
            />
            <Item3 
                Img={'/img/2 task.svg'} 
                Title={'Указать контакты'} 
                Desc={'+20 баллов'} 
                Btn={'Выполнить'}
                status={taskStatus[1]}
                handleClick={() => {
                    if (!taskStatus[1]) {
                        handleClick(userData.user_id, 1); // Передаем userId и индекс
                        setCurrentPage('Contact'); // Переход на страницу контактов
                    }
                }}
            />
            <Item3 
                Img={'/img/3 task.svg'} 
                Title={'Подписаться на TG'} 
                Desc={'+10 баллов'} 
                Btn={'Выполнить'}
                status={taskStatus[2]}
                handleClick={!taskStatus[2] ? handleClick('https://t.me/ulalogistics24', userData.user_id, 2) : () => {}}
            />
            <Item3 
                Img={'/img/4 task.svg'} 
                Title={'Подписаться на VK'} 
                Desc={'+10 баллов'} 
                Btn={'Выполнить'}
                status={taskStatus[3]}
                handleClick={!taskStatus[3] ? handleClick('https://vk.com/ulalogistics24', userData.user_id, 3) : () => {}}
            />
            <Item3 
                Img={'/img/5 task.svg'} 
                Title={'Подписаться на VC.RU'} 
                Desc={'+10 баллов'} 
                Btn={'Выполнить'}
                status={taskStatus[4]}
                handleClick={!taskStatus[4] ? handleClick('https://vc.ru/u/4015363-ulaglobal', userData.user_id, 4) : () => {}}
            />
            <span className="title-type-1">FAQ</span>
            <Item2 
                Title={'Ответы на частые вопросы'} 
                Btn={'Перейти'}
                handleClick={() => handleClick('FAQ')}
                style={{marginBottom: '100px'}}
            />
        </>
    )
}

export default TasksPage;