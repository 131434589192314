import React from "react";
import { useState } from "react";
import NavBar from "../NavBar";
import './PersonalAccount.css'

const ToggleDescriptionItem = ({ title, description }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div style={{ width: '90%', margin: '5% auto', fontWeight: 500 }}>
            <div 
                onClick={handleToggle} 
                style={{
                    cursor: 'pointer', 
                    padding: '10px', 
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <span>{title}</span>
                <span className={`arrow ${isOpen ? 'down' : 'up'}`}>▼</span>
            </div>
            <div 
                className={`description ${isOpen ? 'open' : ''}`} 
                style={{
                    overflow: 'hidden',
                    transition: 'max-height 0.3s ease',
                    maxHeight: isOpen ? '500px' : '0', // Устанавливаем максимальную высоту для анимации
                    padding: isOpen ? '10px' : '0',
                    fontSize: '90%',
                    fontWeight: '300'
                }}
            >
                {description}
            </div>
        </div>
    );
};

const PrimaryAccount = ({ userData }) => {
    return (
        <>
            
                <div className="user-pa-balance-wrap"  style={{backgroundImage: 'url("img/ula-logo-bg-pa.svg")'}}>
                    <span className="your-balance">Ваш баланс:</span>
                    <span className="user-pa-balance-value">{userData.balance} баллов</span>
                    <span className="user-pa-balance-tasks-btn">Задания</span>
                </div>
                <span className="title-type-2">Вы участвуете</span>
                <div className="user-pa-item-wrap">
                <span className="user-pa-balance-lotery-btn">Розыгрыш</span>
                    <span className="user-pa-item-title">КОНКУРС НА 100.000 ₽</span>
                    <span className="user-pa-item-desc">Итоги 14.12.2024 на нашем Telegram канале</span>
                </div>
                
            {/* <span className="next-question-btn">Далее 1/5</span> */}
        </>
    )
}

export default PrimaryAccount;