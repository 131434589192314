import React, { useState } from "react";
import NavBar from "../NavBar";
import './QuestionPage.css';

const Question = ({ title, Variables, onSelectAnswer }) => {
    const [activeIndex, setActiveIndex] = useState(null); // Состояние для хранения индекса активного ответа

    const handleAnswerClick = (index) => {
        setActiveIndex(index); // Устанавливаем активный индекс
        onSelectAnswer(Variables[index]); // Передаем текст ответа в родительский компонент
    };

    return (
        <>
            <div className="question-wrap">
                <span className='question-title'>{title}</span>
                <span className="question-guid">Выберите один из доступных ответов</span>
            </div>

            {
                Variables.map((text, index) => (
                    <span 
                        key={index} 
                        className={`answer${activeIndex === index ? '-act' : ''}`} // Добавляем класс 'act' к активному элементу
                        onClick={() => handleAnswerClick(index)} // Обработка клика по ответу
                    >
                        {text}
                    </span>
                ))
            }
        </>
    );
}


const QuizePage = ({ userData, setCurrentPage }) => {
    const [counter, setCounter] = useState(1);
    const [selectedAnswer, setSelectedAnswer] = useState(null); // Состояние для хранения выбранного ответа
    if (counter === 6){
        setCurrentPage("FinPage")
    }
    if (counter === 0){
        setCurrentPage('main')
    }
    const handleAnswerSelected = (answer) => {
        setSelectedAnswer(answer); // Устанавливаем выбранный ответ
    };

    const handleNextQuestion = async () => {
        if (!selectedAnswer) {
            alert("Пожалуйста, выберите ответ перед переходом к следующему вопросу.");
            return;
        }

        // Увеличиваем счетчик
        setCounter(counter + 1);

        // Отправляем запрос
        try {
            const response = await fetch(`https://webapp.ula.global/api/users/questions/${counter}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({"user_id": userData.user_id, "answer": selectedAnswer }), // Добавляем выбранный ответ в теле запроса
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log('Response:', data); // Обработка ответа от сервера

            // Сбрасываем выбранный ответ после отправки
            setSelectedAnswer(null);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handlePrevQuestion = async () => {

        // Увеличиваем счетчик
        setCounter(counter - 1);

        // Отправляем запрос
        try {
            const response = await fetch(`https://webapp.ula.global/api/users/questions/${counter}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({"user_id": userData.user_id, "answer": selectedAnswer }), // Добавляем выбранный ответ в теле запроса
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log('Response:', data); // Обработка ответа от сервера

            // Сбрасываем выбранный ответ после отправки
            setSelectedAnswer(null);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    return (
        <>
            <span className="question-main-title">Вопрос {counter}</span>
            {counter === 1 && (
                <Question 
                    title="Каким способом Вы обычно доставляете грузы?"
                    Variables={['Авто', 'Ж/Д', 'Морская', 'Авиа']} 
                    onSelectAnswer={handleAnswerSelected} 
                />
            )}
            {counter === 2 && (
                <Question 
                    title="Как часто вы отправляете грузы?"
                    Variables={['Каждый день', 'Каждую неделю', 'Каждый месяц', 'Несколько раз в квартал', 'Несколько раз в год']} 
                    onSelectAnswer={handleAnswerSelected} 
                />
            )}
            {counter === 3 && (
                <Question 
                    title="Какие категории товаров возите чаще всего?"
                    Variables={['Народного потребления', 'Сельское хозяйство', 'Станки', 'Сельское хозяйство', 'Продукты', 'Медицинские']} 
                    onSelectAnswer={handleAnswerSelected} 
                />
            )}
            {counter === 4 && (
                <Question 
                    title="Как вы приобретаете Ваш товар в Китае?"
                    Variables={['На маркетплейсах', 'Через агентов(байер)', 'Напрямую', 'В поисках поставщика']} 
                    onSelectAnswer={handleAnswerSelected} 
                />
            )}
            {counter === 5 && (
                <Question 
                    title="Как Вы оплачиваете счета за товар поставщику? "
                    Variables={['Наличными', 'Платежный агент', 'Через банк', 'В поисках платежного решения']} 
                    onSelectAnswer={handleAnswerSelected} 
                />
            )}

            <span className="prev-question-btn" onClick={handlePrevQuestion}>Назад</span>
            <span className="next-question-btn" onClick={handleNextQuestion}>Далее {counter}/5</span>
        </>
    );
}

export default QuizePage;
