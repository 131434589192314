import React from "react";
import { useState, useEffect } from "react";
import NavBar from "../NavBar";
import './FinQuizPage.css'

const FinQuizPage = ({ userData, setCurrentPage }) => {

    const initialTaskStatus = [false, false, false, false, false]; // Изначально все задачи не выполнены
    const [taskStatus, setTaskStatus] = useState(initialTaskStatus);

    

    useEffect(() => {
        // Функция для отправки запроса
        const claimTask = async () => {
            const url = `https://webapp.ula.global/api/tasks/${userData.user_id}/ClaimTask/1`;

            try {
                const response = await fetch(url, {
                    method: 'POST', // Используем метод POST
                    headers: {
                        'Content-Type': 'application/json' // Указываем тип содержимого
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`); // Обработка ошибок
                }

                const data = await response.json(); // Получаем данные из ответа
                console.log(data); // Выводим данные в консоль или используем по необходимости

            } catch (error) {
                console.error('Ошибка при выполнении запроса:', error); // Обработка ошибок
            }
        };

        claimTask(); // Вызываем функцию при монтировании компонента

        // Разбираем строки JSON из userData
        const tasks = JSON.parse(userData.tasks || '{}');
        const updatedStatus = [...initialTaskStatus];

        // Устанавливаем статус задач на основе данных из userData
        for (let i = 0; i < updatedStatus.length; i++) {
            if (tasks[i + 1] === 'finished') {
                updatedStatus[i] = true; // Устанавливаем статус как выполненный
            }
        }
        if (userData.questions && userData.questions.length >= 5) {
            updatedStatus[0] = true; // Статус первого элемента будет true
        }

        // Проверяем условия для второго элемента
        if (userData.contact && userData.company_name) {
            updatedStatus[1] = true; // Статус второго элемента будет true
        }
        
        setTaskStatus(updatedStatus);
    }, [userData]); // Зависимость от userData


    return (
        <>
                <div className="succes-wrap" style={{backgroundImage: 'url("img/logo-succes.svg")'}}>
                    <span className="succes-increment">+50 баллов</span>
                    <span className="succes-title">Успех</span>
                    <span className="succes-desc">Благодарим за участие в опросе! <br/>ULA вышлет вам коммерческое<br/> предложение на почту, подпишитесь <br/>на наши соц сети, чтобы получить купон! </span>
                </div>
                <span className="title-type-2">Вам полагается</span>
                <div className="user-pa-balance-wrap"  style={{backgroundImage: 'url("img/ula-logo-bg-pa.svg")'}}>
                    <span className="your-balance">Ваш баланс:</span>
                    <span className="user-pa-balance-value">{userData.balance} баллов</span>
                    <span className="user-pa-balance-tasks-btn">Задания</span>
                </div>
                
                <div className="lottery-cupone-wrap-type-1" >
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <span className="lottery-cupone-title">Поздравляем!</span>
                        <span className="lottery-cupone-desc">Вам открыт доступ<br></br> к купону на 5.000 ₽</span>
                    </div>
                    <img src="img/cupone.svg"/>
                </div>
                <div className="get-check">Получить купон</div>
                
                <div className="lottery-cupone-wrap" style={{backgroundImage: 'url("img/track.png")'}}>
                    <span className="lottery-cupone-title">Розыгрыш купона <br></br> на 100.000 ₽</span>
                    <span className="lottery-cupone-desc">Требуется 100 баллов</span>
                </div>
                <div className="locked" onClick={() => setCurrentPage('tasks')}>{taskStatus[0] && taskStatus[1] && taskStatus[2] && taskStatus[3] && taskStatus[4] ? "Выполнено" : "🔒 Заблокировано"}</div>
                
            {/* <span className="next-question-btn">Далее 1/5</span> */}
        </>
    )
}

export default FinQuizPage;