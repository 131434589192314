import React from "react";
import './Item1.css'

const Item1 = ({Img, Title, Btn, handleClick}) => {
    return (
        <div className="item-1-wrap" onClick={handleClick}>
            <img src={Img} className="item-1-img"/>
            <span className="item-1-title">{Title}</span>
            <span className="item-1-btn-abl">{Btn}</span>
        </div>
    )
}

export default Item1;