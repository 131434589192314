import React from "react";
import './LinkBlock.css'

const LinkBlock = ({ userData }) => {

    const copyToClipboard = () => {
        navigator.clipboard.writeText(`https://t.me/Ulaprize_bot/Webappula?startapp=${userData.user_id}`);
    };

    return (
        <>
            <div className="link-block">
                <span className="link-block-title">
                    Пройдите небольшой опрос и получите
                </span>
                <span className="link-block-desc">
                    Пройдите небольшой опрос и получите <br></br>
                    возможность выиграть 100 000 ₽ <br></br>
                    на закупку в Китае
                </span>
                <span className="link-block-sourse">{`https://t.me/Ulaprize_bot/Webappula?startapp=${userData.user_id}`}</span>
                <div style={{display: 'flex', flexDirection: 'row', width: '98%', justifyContent: 'space-between'}}>
                    <button className="link-block-btn" onClick={copyToClipboard}>Скопировать</button>
                    <button className="link-block-btn">Поделиться</button>
                </div>
                
            </div>
        </>
    )
}

export default LinkBlock;