import React from "react";
import './Item3.css'

const Item3 = ({ Img, Title, Desc, Btn, handleClick, status }) => {
    console.log(status)
    return (
        <div className="item-3-wrap">
            <img src={Img} className="item-3-img"/>
            <div className="item-3-text-wrap">
                <span className="item-3-title">{Title}</span>
                <span className="item-3-desc">{Desc}</span>
            </div>
            
            <span className={status ? "item-3-btn-disabl" : "item-3-btn-abl"} onClick={handleClick}>{status ? 'Выполнено' : 'Выполнить'}</span>
        </div>
    )
}

export default Item3;