import React from "react";
import './FriendItem.css'

const FriendItem = ({Img, Name, Username, Date}) => {
    return (
        <div className="friend-wrap">
            <img src={Img} className="friend-img"/>
            <div className="friend-text-wrap-left">
                <span className="friend-title">{Name}</span>
                <span className="friend-desc">{Username}</span>
            </div>
            <div className="friend-text-wrap-right">
                <span className="friend-title">Приглашен:</span>
                <span className="friend-desc">{Date}</span>
            </div>
        </div>
    )
}

export default FriendItem;