import React from "react";
import NavBar from "../NavBar";
import QuizBlock from "../components/items/QuizBlock";
import Item1 from "../components/items/Item1";
import Item2 from "../components/items/Item2";
import PostItem from "../components/items/PostItem";

const MainPage = ({ currentPage, setCurrentPage }) => {
    const handleClick = () => {
        setCurrentPage('FAQ')
    };
    return (
        <>
            
            <QuizBlock setCurrentPage={setCurrentPage}/>
            {/* <span className="title-type-1">Посты</span> */}
            {/* <PostItem 
                Title={"Покупка авто в Китае"}
                Description={"Недавно мы успешно завершили проект по покупке и доставке Cherry Tiggo 8"}
                Background_url={'https://s3-alpha-sig.figma.com/img/31de/0f12/1fa306a3a5d9045edf5d0a2b726fb018?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RhCWp9uLd~fka9PgF5Uyy2t6FgYKBFXMO-MCwuABxIovk8vIgkr1x0DCvrsxZ5R025Z~Oco6-N7qFl7WCFWfwpTkfNpWXjsd9zLw0lw~O87NDVzpc8TaqSDs7dplg0uXw33P~8V8gk2oF2e3qGwVFR9TKg-VdDgg3rvY9XEJ-zI0BFSPpA28ikQsafHtIGIsA8TiKxT84IdaxSopiire4mLIPB7dglydtKXMWeCcz32soFSx8rFVVp~2EdOrohKO0pKuXtm87Xero19FpGQqIIKVIEAQpG9yHNpVFzuWftT75kk2YiXuCsiykrRy0VuovDt0VIorH4UdHW6hCp5Oxg__'}
            /> */}
            <span className="title-type-1">О ULA</span>
            <Item1 
                Img={'/img/3 task.svg'} 
                Title={'Telegram'} 
                Btn={'Перейти'}
                handleClick={() => window.open('https://t.me/ulalogistics24', '_blank')}
            />
            <Item1 
                Img={'/img/4 task.svg'} 
                Title={'Вконтакте'} 
                Btn={'Перейти'}
                handleClick={() => window.open('https://vk.com/ulalogistics24')}
            />
            <Item1 
                Img={'/img/5 task.svg'} 
                Title={'VC.RU'} 
                Btn={'Перейти'}
                handleClick={() => window.open('https://vc.ru/u/4015363-ulaglobal', '_blank')}
            />
            <span className="title-type-1">FAQ</span>
            <Item2 
                Title={'Ответы на частые вопросы'} 
                Btn={'Перейти'}
                handleClick={handleClick}
            />
        </>
    )
}

export default MainPage;