import React from "react";
import "./NavBar.css"

const NavBar = ({setCurrentPage}) => {
    return(
        <div className="header">
            <img src="img/logo.svg" className="header-logo"/>
            <div className="gift-block">
                <img src="img/gift.svg"/>
                <span 
                    onClick={() => setCurrentPage('Quiz')}
                >Розыгрыш</span>
            </div>
        </div>
    )
}

export default NavBar;