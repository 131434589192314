import React, { useEffect, useState } from 'react';
import NavBar from "../NavBar";
import QuizBlock from "../components/items/QuizBlock";
import Item1 from "../components/items/Item1";
import Item2 from "../components/items/Item2";
import PostItem from "../components/items/PostItem";
import Item3 from "../components/items/Item3";
import LinkBlock from "../components/items/LinkBlock";
import FriendItem from "../components/items/FriendItem";

const RefPage = ({ userData }) => {
    const [friends, setFriends] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchReferrals = async () => {
            try {
                const response = await fetch(`https://webapp.ula.global/api/users/resolveReferrals/${userData.user_id}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setFriends(data); // Предполагаем, что API возвращает массив рефералов
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchReferrals();
        console.log(friends)
    }, [userData.user_id]);


    if (loading) {
        return <div>Загрузка...</div>;
    }

    if (error) {
        return <div>Ошибка: {error}</div>;
    }

    return (
        <>
            <QuizBlock />
            <LinkBlock userData={userData}/>
            <span className="title-type-1">Пригласили {friends && friends.length} человек!</span>
            {!loading && friends.map((friend, index) => (
                <FriendItem
                    key={index}
                    Name={friend.name} // Предполагаем, что объект friend имеет поле name
                    Username={friend.username} // Предполагаем, что объект friend имеет поле username
                    Date={friend.registration_date} // Предполагаем, что объект friend имеет поле date
                />
            ))}
        </>
    );
};

export default RefPage;