import React from "react";
import './NavPan.css'

const NavPan = ({ currentPage, setCurrentPage }) => {
    const handleNavClick = (page) => {
        setCurrentPage(page);
    };

    const getImageSrc = (page) => {
        return currentPage === page ? `img/${page}-page-act.svg` : `img/${page}-page-def.svg`;
    };

    return (
        <div className="nav-pan">
            <div 
                className={currentPage === 'main' ? 'nav-pan-act-item' : 'nav-pan-def-item'} 
                onClick={() => handleNavClick('main')}
            >
                <img src={getImageSrc('main')} alt="Главная"/>
                <span>Главная</span>
            </div>
            <div 
                className={currentPage === 'tasks' ? 'nav-pan-act-item' : 'nav-pan-def-item'} 
                onClick={() => handleNavClick('tasks')}
            >
                <img src={getImageSrc('tasks')} alt="Задания"/>
                <span>Задания</span>
            </div>
            <div 
                className={currentPage === 'ref' ? 'nav-pan-act-item' : 'nav-pan-def-item'} 
                onClick={() => handleNavClick('ref')}
            >
                <img src={getImageSrc('ref')} alt="Зовите друзей"/>
                <span>Зовите друзей</span>
            </div>
            <div 
                className={currentPage === 'Personal-account' ? 'nav-pan-act-item' : 'nav-pan-def-item'} 
                onClick={() => handleNavClick('Personal-account')}
            >
                <img src={getImageSrc('Personal-account')} alt="Личный кабинет"/>
                <span>Личный кабинет</span>
            </div>
        </div>
    );
};

export default NavPan;