import React, { useState } from "react";
import './Contacts.css';

const Contacts = ({ userData, setCurrentPage }) => {
    const [formData, setFormData] = useState({
        name: '',
        company: '',
        contact: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); 
        
        // Сначала отправляем запрос на изменение контактов
        const contactsUrl = 'https://webapp.ula.global/api/users/contacts/changeContacts';
        
        try {
            const contactsResponse = await fetch(contactsUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user_id: userData.user_id, 
                    name: formData.name,
                    company_name: formData.company,
                    contacts: formData.contact
                })
            });

            if (!contactsResponse.ok) {
                throw new Error(`Ошибка при изменении контактов! статус: ${contactsResponse.status}`);
            }

            // Если запрос успешен, отправляем второй запрос
            const claimTaskUrl = `https://webapp.ula.global/api/tasks/${userData.user_id}/ClaimTask/2`;
            const claimTaskResponse = await fetch(claimTaskUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!claimTaskResponse.ok) {
                throw new Error(`Ошибка при выполнении задачи! статус: ${claimTaskResponse.status}`);
            }

            const data = await claimTaskResponse.json();
            console.log(data); 
            setCurrentPage('tasks');

        } catch (error) {
            console.error('Ошибка при выполнении запроса:', error);
        }
    };

    return (
        <form className="contact-form-wrap" onSubmit={handleSubmit}>
            <span className="title-type-2">Указать свои контакты</span>
            <input 
                name="name" 
                placeholder="Введите ваше имя" 
                value={formData.name} 
                onChange={handleChange} 
            />
            <input 
                name="company" 
                placeholder="Введите название компании" 
                value={formData.company} 
                onChange={handleChange} 
            />
            <input 
                name="contact" 
                placeholder="Введите ваш номер телефона/ E-mail" 
                value={formData.contact} 
                onChange={handleChange} 
            />
            <button type="submit">Выполнить</button>
        </form>
    );
};

export default Contacts;
