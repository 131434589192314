import React from "react";
import { useState } from "react";
import "./FAQ.css"


const ToggleDescriptionItem = ({ title, description }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div style={{ width: '95%', margin: '1% auto', fontWeight: 500 }}>
            <div 
                onClick={handleToggle} 
                style={{
                    cursor: 'pointer', 
                    padding: '10px', 
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <span>{title}</span>
                <span className={`arrow ${isOpen ? 'down' : 'up'}`}>▼</span>
            </div>
            <div 
                className={`description ${isOpen ? 'open' : ''}`} 
                style={{
                    overflow: 'hidden',
                    transition: 'max-height 0.3s ease',
                    maxHeight: isOpen ? '500px' : '0', // Устанавливаем максимальную высоту для анимации
                    padding: isOpen ? '10px' : '0',
                    fontSize: '90%',
                    fontWeight: '300'
                }}
            >
                {description}
            </div>
        </div>
    );
};



const FAQ = () => {
    return(
        <>
            <span className="faq-main-title">FAQ</span>
            <ToggleDescriptionItem 
                title="Какой опыт в логистике у компании?" 
                description="Уже более 7-ми лет мы занимаемся доставкой грузов из Китая, Южной Кореи и других стран Азии. Через наши руки прошло несколько тысяч разнообразных товаров, поэтому мы на своём опыте узнали все тонкости и аспекты международной перевозки"
            />
            <ToggleDescriptionItem 
                title="Какие услуги предоставляет ЮЛА?" 
                description="Мы принимаем все необходимые меры безопасности, чтоб быть уверенными в благополучной доставке товара. Начиная с качественной упаковки и заканчивая страхованием груза на 100%. Вы можете быть уверены в сохранности своих товаров, а чтоб вы лишний раз не волновались - оплату мы проводим только после успешного прохождения таможни. "
            />
            <ToggleDescriptionItem 
                title="Как можно посмотреть(отследить) местоположение груза?" 
                description="Любой наш клиент может отслеживать груз через личный кабинет ЮЛЫ. Для этого достаточно зарегистрироваться на сайте и тогда вы сможете видеть все передвижения вашего груза "
            />
            <ToggleDescriptionItem 
                title="Где посмотреть отзывы и рекомендации? " 
                description="В данный момент вы можете посмотреть отзывы в специальном блоке на нашем сайте"
            />
            <ToggleDescriptionItem 
                title="Какие дополнительные услуги предлагает компания?" 
                description="Мы являемся комплексным решением, которое подойдёт каждому. В список дополнительных услуг входят такие, как: поиск надёжного поставщика и дальнейшая оплата накладных, доставка автомобилей, ЮЛА выкуп"
            />
        </>
    )
}

export default FAQ;