// Preloader.js
import React from 'react';
import './Preloader.css'; // Импортируем CSS для стиля прелоадера

const Preloader = () => {
  return (
    <div className="preloader">
      <div className="spinner"></div>
      <p>Загрузка...</p>
    </div>
  );
};

export default Preloader;
