import LinkBlock from "./components/items/LinkBlock";
import NavBar from "./NavBar";
import NavPan from "./NavPanel";
import MainPage from "./pages/MainPage";
import PrimaryAccount from "./pages/PersonalAccount";
import QuizePage from "./pages/QuizPage";
import RefPage from "./pages/RefPage";
import TasksPage from "./pages/TasksPage";
import FAQ from "./pages/FAQ";
import { useState, useEffect } from "react";
import Contacts from "./pages/Contacts";
import FinQuizPage from "./pages/FinQuizPage";
import Preloader from "./components/items/Preloader";

function App() {
  const [currentPage, setCurrentPage] = useState('main'); // Начальная страница
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true); // Состояние загрузки

  async function submitAnswer() {
    const url = 'https://webapp.ula.global/api/users/resolveUser';
    const tg = window.Telegram.WebApp;


    // Получение данных пользователя
    const data = {
      user_id: tg.initDataUnsafe.user.id,
      first_name: tg.initDataUnsafe.user.first_name,
      username: tg.initDataUnsafe.user.username,
    };


    // const data = {
    //   user_id: 8334163,
    //   first_name: "kir",
    //   username: "kir",
    //   ref_code: 18934163 // Используем user_id как реферальный код
    // };

    const startParam = tg.initDataUnsafe.start_param;
    if (startParam) {
      data.ref_code = startParam;
    }

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const Data = await response.json();
      setUserData(Data);
      setLoading(false); // Устанавливаем состояние загрузки в false после получения данных
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      setLoading(false); // Устанавливаем состояние загрузки в false даже в случае ошибки
    }
  }

  useEffect(() => {
    submitAnswer();
    const intervalId = setInterval(() => {
      submitAnswer();
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  const renderPage = () => {
    switch (currentPage) {
      case 'main':
        return <MainPage currentPage={currentPage} setCurrentPage={setCurrentPage} />;
      case 'tasks':
        return <TasksPage currentPage={currentPage} setCurrentPage={setCurrentPage} userData={userData}/>;
      case 'ref':
        return <RefPage userData={userData}/>;
      case 'Personal-account':
        return <PrimaryAccount userData={userData}/>;
      case 'Quiz':
        return <QuizePage setCurrentPage={setCurrentPage} userData={userData}/>;
      case 'FAQ':
        return <FAQ />;
      case 'Contact':
        return <Contacts userData={userData} setCurrentPage={setCurrentPage}/>;
        case 'FinPage':
          return <FinQuizPage setCurrentPage={setCurrentPage} userData={userData} />;
        default:
          return <MainPage />;
      }
    };
  
    return (
      <div className="App">
        {loading ? <Preloader /> : (
          <>
            <NavBar setCurrentPage={setCurrentPage} />
            {renderPage()}
            <NavPan currentPage={currentPage} setCurrentPage={setCurrentPage} />
          </>
        )}
      </div>
    );
  }
  
  export default App;